import theme from "../../themes";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import styled from "@emotion/styled";
import generateColorArray from "../../utils/generateColorArray";
import { GetDomain } from "./GetDomain";

const TooltipImage = ({ src }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <img
        src={src}
        style={{
          width: "50%",
          height: "100%",
          objectFit: "contain",
        }}
      />
    </div>
  );
};

const RoundedBar = (props) => {
  const { fill, x, y, width, height } = props;

  return (
    <>
      <Rectangle
        {...props}
        radius={[0, 10, 10, 0]}
        fill={fill}
        x={x}
        y={y}
        width={width}
        height={height}
      />
    </>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: theme.palette.backgroundShade,
          paddingLeft: "3px",
          paddingRight: "3px",
          paddingBottom: "1px",
          paddingTop: "1px",
          borderRadius: theme.bubblePadding.small,
        }}
      >
        {Object.keys(payload[0].payload).map((key) =>
          payload[0].payload[key] === label ? (
            <p key={key} style={{ color: theme.font.color.dim }}>
              {`${payload[0].payload[key]}`}
            </p>
          ) : key.includes(" Url") ? (
            <TooltipImage src={payload[0].payload[key]} />
          ) : (
            <p key={key} style={{ color: payload[0].fill }}>
              {`${key}: ${payload[0].payload[key]}`}
            </p>
          )
        )}
      </div>
    );
  }
};

function QABarChart({ data, independentVariable, dependentVariables }) {
  const ChartColors = generateColorArray({
    color0: theme.palette.primary,
    color1: theme.palette.secondary,
  });
  let [gridlines, domainMin, domainMax] = GetDomain({
    data,
    dependentVariables,
  });

  return (
    <ResponsiveContainer width="100%" height={600}>
      <BarChart
        data={data}
        layout="vertical"
        margin={{
          right: 100,
          left: 100,
        }}
      >
        <CartesianGrid
          strokeDasharray="3 3"
          strokeWidth={0.2}
          stroke={theme.font.color.dimExtra}
        />
        <XAxis
          type="number"
          domain={[domainMin, domainMax]}
          tickFormatter={(value) => parseFloat(value.toFixed(2))}
          ticks={gridlines}
        />
        <YAxis
          dataKey={independentVariable}
          type="category"
          tick={{ fontSize: 14, width: 300 }}
          padding={{ top: 10, bottom: 10 }}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        {dependentVariables.map((
          dVariable,
          index // loop through and create a Bar for each dependent variable
        ) => (
          <Bar
            key={index}
            dataKey={dVariable}
            fill={ChartColors[index]}
            barSize={10}
            shape={<RoundedBar />}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
}

export default QABarChart;
