// for Email and Password

import React from "react";
import Box from "@mui/material/Box";

import AuthTextField from "./AuthTextField";
import SubmitButton from "../base/SubmitButton";
import theme from "../../themes";

const SingleFieldForm = ({
  onSubmit,
  onChange,
  label,
  autoComplete,
  inputProps = {},
  submitButtonText = "",
  isPasswordField = false,
}) => {
  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      onSubmit(event);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      noValidate
      sx={{
        mt: 1,
        minWidth: "300px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "0px",
      }}
    >
      <AuthTextField
        margin="normal"
        required
        fullWidth
        type={isPasswordField ? "password" : "text"}
        name={autoComplete}
        id={label}
        label={label}
        autoComplete={autoComplete}
        onChange={onChange}
        variant="outlined"
        autoFocus
        inputProps={{
          ...inputProps,
        }}
        InputLabelProps={{
          style: { color: theme.font.color.primary },
        }}
      />
      <SubmitButton text={submitButtonText} onSubmit={onSubmit} />
    </Box>
  );
};

export default SingleFieldForm;
