// check if a user is already logged in in the backend

import ApiService from "../services/ApiService";
import Cookies from "universal-cookie";

const cookies = new Cookies();

async function ValidateUser({ navigate }) {
  try {
    const response = await ApiService("get", "user/validate");
    if (response.status === 200) {
      const authToken = response.data?.auth_token;
      cookies.set("auth_token", authToken, {
        path: "/",
        secure: true,
      });
      const nextUrl = response.data?.next_url;
      navigate(nextUrl || "/ask");
    }
  } catch (error) {}
}

export default ValidateUser;
