import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { faChartLine, faTable } from "@fortawesome/free-solid-svg-icons";

import Switch from "../base/Switch";

const ContainerHeight = 30;
const SelectedButtonHeight = 25;
const ButtonWidth = 40;
const HorizontalMargin = 5;
const SelectedButtonVerticalMargin = 4;

const Options = [
  { name: "table", disabled: false, icon: faTable },
  { name: "chart", disabled: false, icon: faChartLine },
];

// TODO: remove this container?
const SwitchContainer = styled("div")({
  // make all items aligned vertically
  // marginRight: "auto",
});

const VizSwitch = ({ showChart, setShowChart }) => {
  const initialButtonState = showChart ? "chart" : "table";
  const [selectedButton, setSelectedButton] = useState(initialButtonState);

  useEffect(() => {
    if (selectedButton === "chart") {
      setShowChart(true);
    } else {
      setShowChart(false);
    }
  }, [selectedButton]);

  return (
    <SwitchContainer>
      <Switch
        selectedButton={selectedButton}
        setSelectedButton={setSelectedButton}
        options={Options}
        containerHeight={ContainerHeight}
        buttonWidth={ButtonWidth}
        horizontalMargin={HorizontalMargin}
        selectedButtonHeight={SelectedButtonHeight}
        selectedButtonVerticalMargin={SelectedButtonVerticalMargin}
      />
    </SwitchContainer>
  );
};

export default VizSwitch;
