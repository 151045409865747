import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AuthPanel from "../components/users/AuthPanel";
import PageBackground from "../components/base/PageBackground";
import AlertMessage from "../components/base/AlertMessage";

import VerificationCodeForm from "../components/users/VerificationCodeForm";
import RegisterForm from "../components/users/RegisterForm";

const PageTitle = "Welcome to GameGuru";
const VerificationCodeTitle = "A verification code was sent to your email";

function RegisterPage() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [formStep, setFormStep] = useState(0); // 0 = registerform, 1 = verification code

  const location = useLocation();
  const email = location.state.email;

  // this is used for both RegisterForm and VerificationCodeForm, hence defined at Page level
  const [completedForm, setCompletedForm] = useState({
    email: email,
    password1: "",
    password2: "",
  });

  return (
    <PageBackground>
      {formStep === 0 && (
        <AuthPanel title={PageTitle}>
          <RegisterForm
            completedForm={completedForm}
            setCompletedForm={setCompletedForm}
            setFormStep={setFormStep}
            setErrorMessage={setErrorMessage}
          />
        </AuthPanel>
      )}
      {formStep === 1 && (
        <AuthPanel title={VerificationCodeTitle}>
          <VerificationCodeForm
            completedForm={completedForm}
            setCompletedForm={setCompletedForm}
            setErrorMessage={setErrorMessage}
            navigate={navigate}
          />
        </AuthPanel>
      )}
      <AlertMessage
        message={errorMessage}
        setMessage={setErrorMessage}
        success={false}
      />
    </PageBackground>
  );
}

export default RegisterPage;
