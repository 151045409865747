import { useState } from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbsUp,
  faThumbsDown,
  faShareNodes,
  faFileCsv,
} from "@fortawesome/free-solid-svg-icons";

import theme from "../../themes.js";
import VizSwitch from "./VizSwitch.js";
import StyledTooltip from "../base/StyledTooltip.js";
import FeedbackForm from "../feedback/FeedbackForm.js";
import { QAQuestions } from "../feedback/FeedbackQuestions.js";

const Container = styled("div")({
  marginTop: "20px",
  borderTop: `1px solid ${theme.palette.backgroundShade}`,
});

const ItemsContainer = styled("div")({
  margin: "10px 5px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const ButtonStyle = ({ disabled = false }) => ({
  border: "none",
  backgroundColor: "transparent",
  color: theme.font.color.dimExtra,
  cursor: "pointer",
  fontSize: theme.iconSize.medium,
  ...(disabled
    ? {}
    : {
        "&:hover": {
          color: theme.palette.primary,
        },
      }),
  opacity: disabled ? 0.5 : 1,
});

const ShareButton = styled("button")((props) => ({
  ...ButtonStyle(props),
  marginLeft: "auto",
}));

const DownloadButton = styled("button")((props) => ({
  ...ButtonStyle(props),
  marginLeft: "20px",
}));

const FeedbackButton = styled("button")((props) => ({
  ...ButtonStyle(props),
  marginLeft: "20px",
}));

const FeedbackContainer = ({
  showFeedback,
  setShowFeedback,
  closeForm,
  qaSummary,
}) => {
  const onClickUp = () => {
    setShowFeedback(true);
    qaSummary["summary"] = true;
  };

  const onClickDown = () => {
    setShowFeedback(true);
    qaSummary["summary"] = false;
  };

  // TODO: at some point handle this
  qaSummary["made_manual_request"] = false;

  return (
    <>
      {showFeedback && (
        <FeedbackForm
          title="QA Feedback"
          closeForm={closeForm}
          questions={QAQuestions}
          feedbackType="qa"
          additionalFeedbackData={qaSummary}
        />
      )}
      <>
        <FeedbackButton onClick={onClickUp}>
          <FontAwesomeIcon icon={faThumbsUp} />
        </FeedbackButton>
        <FeedbackButton onClick={onClickDown}>
          <FontAwesomeIcon icon={faThumbsDown} />
        </FeedbackButton>
      </>
    </>
  );
};

function QueryActionBar({ isChartOption, showChart, setShowChart, qaSummary }) {
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const closeForm = () => {
    setShowFeedbackForm(false);
  };

  return (
    <Container>
      <ItemsContainer>
        {isChartOption && (
          <VizSwitch showChart={showChart} setShowChart={setShowChart} />
        )}
        <StyledTooltip title="Coming soon!" placement="top">
          <ShareButton disabled={true}>
            <FontAwesomeIcon icon={faShareNodes} />
          </ShareButton>
        </StyledTooltip>
        <StyledTooltip title="Coming soon!" placement="top">
          <DownloadButton disabled={true}>
            <FontAwesomeIcon icon={faFileCsv} />
          </DownloadButton>
        </StyledTooltip>
        <FeedbackContainer
          showFeedback={showFeedbackForm}
          setShowFeedback={setShowFeedbackForm}
          closeForm={closeForm}
          qaSummary={qaSummary}
        />
      </ItemsContainer>
    </Container>
  );
}

export default QueryActionBar;
