import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import ValidateToken from "../endpoints/ValidateToken";

import PageBackground from "../components/base/PageBackground";

function PrivateRoute({ children }) {
  const [isValidAuthToken, setIsValidAuthToken] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    ValidateToken({ setIsValidAuthToken, setIsLoading });
  }, []);

  if (isLoading) {
    return <PageBackground />;
  }

  return isValidAuthToken ? children : <Navigate to="/welcome" />;
}

export default PrivateRoute;
