import theme from "../../themes";

const QueryStyles = {
  backgroundColor: theme.palette.backgroundShade,
  fontSize: theme.font.size.medium,
  marginTop: "20px",
  marginBottom: "20px",
  width: "100%",
  borderRadius: theme.bubbleBorderRadius,
  color: theme.font.color.primary,
};

export default QueryStyles;
