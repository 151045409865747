import ApiService from "../services/ApiService";

async function EarlyAccess(email, setMessage, setSuccess) {
  try {
    const response = await ApiService("post", "user/early_access", { email });
    setMessage(response?.data.status_message);
    setSuccess(true);
  } catch (error) {
    setMessage(error.response?.data.status_message);
    setSuccess(false);
  }
}

export default EarlyAccess;
