import React, { useRef, useEffect } from "react";
import styled from "@emotion/styled";
import theme from "../../themes";

const FreeTextInput = styled("textarea")({
  width: "100%",
  padding: "5px",
  borderRadius: "10px",
  backgroundColor: theme.palette.backgroundShade,
  border: `1px solid ${theme.palette.backgroundShadeExtra}`,
  fontSize: theme.font.size.medium,
  fontFamily: theme.font.style.primary,
  color: theme.font.color.primary,
  textAlign: "left",
  boxSizing: "border-box",
  resize: "vertical",
  overflow: "hidden",
  whiteSpace: "pre-wrap",
  minHeight: "20px",
  height: "auto",
});

const ResizingTextInput = ({ onChange }) => {
  const textAreaRef = useRef();

  useEffect(() => {
    const textArea = textAreaRef.current;

    const handleInput = (e) => {
      e.target.style.height = "auto";
      e.target.style.height = e.target.scrollHeight + "px";
    };

    textArea.addEventListener("input", handleInput);

    return () => {
      textArea.removeEventListener("input", handleInput);
    };
  }, []);

  return <FreeTextInput ref={textAreaRef} onChange={onChange} />;
};

export default ResizingTextInput;
