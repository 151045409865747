import { lighten, darken } from "@material-ui/core/styles";
import Color from "color";

function isLightColor(color) {
  let rgb = Color(color).rgb().object();

  // Calculate the brightness according to the formula:
  // (Red value X 299 + Green value X 587 + Blue value X 114) / 1000
  const brightness = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;

  // Brightness ranges from 0 to 255.
  // Colors with brightness over 127 are considered light colors
  // while those under 128 are considered dark colors.
  return brightness > 127;
}

function generateColorArray({ color0, color1 }) {
  let colorArray = [color0, color1];

  let isLightColor0 = isLightColor(color0);
  let isLightColor1 = isLightColor(color1);

  let lightenAmount = 0.2;
  let darkenAmount = 0.4;

  for (let i = 0; i < 10; i++) {
    let currentColor = colorArray[colorArray.length - 1];
    if (i % 2 === 0) {
      if (isLightColor0) {
        colorArray.push(darken(currentColor, darkenAmount));
      } else {
        colorArray.push(lighten(currentColor, lightenAmount));
      }
    } else {
      if (isLightColor1) {
        colorArray.push(darken(currentColor, darkenAmount));
      } else {
        colorArray.push(lighten(currentColor, lightenAmount));
      }
    }
  }

  return colorArray;
}

export default generateColorArray;
