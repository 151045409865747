import { useState } from "react";
import styled from "@emotion/styled";
import { Box, Grid, Container } from "@mui/material";
import StyledLogo from "../components/base/StyledLogo";

import theme from "../themes";

import EarlyAccess from "../endpoints/EarlyAccess";
import PageBackground from "../components/base/PageBackground";
import PrimaryTextInput from "../components/base/PrimaryTextInput";
import AlertMessage from "../components/base/AlertMessage";
import IconicButton from "../components/button/IconicButton";
import { useNavigate } from "react-router-dom";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Title = styled(Box)({
  fontSize: "32px",
  color: theme.font.color.primary,
  textAlign: "center",
  marginBottom: "20px",
  marginTop: "20px",
  letterSpacing: "-1px",
});

const LogoContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "20%",
  width: "20%",
  marginBottom: "30px",
});

const LoginButtonContainer = styled(Box)({
  position: "absolute",
  top: "10px",
  right: "10px",
});

function StealthLandingPage() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    EarlyAccess(email, setMessage, setSuccess);
  };

  const handleUATClick = () => {
    navigate("/welcome");
  };

  return (
    <PageBackground>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        bgcolor="transparent"
      >
        <Container maxWidth="m">
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <LogoContainer>
              <StyledLogo />
            </LogoContainer>
            <Title>Unparalleled insights into the beautiful game...</Title>
            <PrimaryTextInput
              placeholder="Get early access"
              width="70%"
              centerText={true}
              onChange={handleChange}
              onSubmit={handleSubmit}
            />
          </Grid>
        </Container>
      </Box>
      <AlertMessage
        message={message}
        setMessage={setMessage}
        success={success}
        messageTime={success ? 5000 : 5000}
      />
      <LoginButtonContainer>
        <IconicButton
          // text={"User Testing"}
          icon={faArrowRight}
          handleClick={handleUATClick}
        />
      </LoginButtonContainer>
    </PageBackground>
  );
}

export default StealthLandingPage;
