import React, { useEffect, useState } from "react";
import { styled, darken } from "@mui/system";
import Container from "@mui/material/Container";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/free-regular-svg-icons";
import { faClockRotateLeft } from "@fortawesome/free-solid-svg-icons";

import theme from "../../themes";
import QAHistory from "../../endpoints/QAHistory";

const openWidth = "400px";
const queryTitleLength = 22; // characters

const translateButton = (-1 * parseInt(openWidth) + 80).toString() + "px";

const PanelOpenButton = styled("button")(({ open }) => ({
  color: theme.palette.primary,
  fontSize: theme.iconSize.medium,
  margin: "10px",
  borderRadius: "50%",
  border: `1px solid ${theme.palette.primary}`,
  backgroundColor: theme.palette.background,
  padding: theme.bubblePadding.medium,
  transform: open ? "translateX(0)" : `translateX(${translateButton})`,
  cursor: "pointer",
  "&:hover": {
    transition: theme.transition.fast,
    color: theme.palette.background,
    backgroundColor: theme.palette.primary,
    border: `1px solid ${theme.palette.primary}`,
  },
}));

const leftMarginAlignment = "15px"; // 10 + this = 10 + 15

// these the indiviudal queries
const PastQueryButton = styled("button")({
  backgroundColor: theme.palette.backgroundShade,
  color: theme.font.color.primary,
  fontSize: theme.font.size.medium,
  fontFamily: theme.font.style.primary,
  textAlign: "left",
  padding: `10px ${leftMarginAlignment} 10px ${leftMarginAlignment}`,
  width: "100%",
  borderRadius: theme.bubbleBorderRadius,
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  border: `1px solid ${theme.palette.backgroundShade}`,
  "&:hover": {
    border: `1px solid ${theme.palette.backgroundShadeExtra}`,
    backgroundColor: theme.palette.backgroundShadeExtra,
    transition: theme.transition.fast,
  },
});

const ClipboardIcon = (
  <FontAwesomeIcon
    icon={faBookmark}
    color={theme.palette.primary}
    style={{ fontSize: theme.iconSize.medium }}
  />
);

const PastQuery = ({ queryTitle, onClick }) => {
  return (
    <PastQueryButton onClick={onClick}>
      {ClipboardIcon}
      <span style={{ marginLeft: "15px" }}>{queryTitle}</span>
    </PastQueryButton>
  );
};

const QueryPanelTitle = styled("div")({
  color: theme.palette.primary,
  fontSize: theme.font.size.medium,
  fontFamily: theme.font.style.secondary,
  textAlign: "left",
  marginBottom: "20px",
  marginLeft: leftMarginAlignment,
  marginTop: "10px",
});

function limitTextLength(text, maxLength = queryTitleLength) {
  return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
}

const PastQueryPanelContainer = styled(Container)(({ open }) => ({
  transition: theme.transition.fast,
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 5,
  paddingRight: 5,
  top: 80,
  transform: open ? "translateX(0)" : "translateX(-100%)",
  backgroundColor: theme.palette.backgroundShade,
  borderTopRightRadius: theme.bubbleBorderRadius,
  borderBottomRightRadius: theme.bubbleBorderRadius,
  boxShadow: `2px 2px 6px 0px ${darken(theme.palette.background, 0.2)}`,
}));

const PastQueryPanel = ({ isPanelOpen, setQueryId }) => {
  const [pastQueries, setPastQueries] = useState([]);
  const [pastQueriesError, setPastQueriesError] = useState(false);

  useEffect(() => {
    QAHistory(setPastQueries, setPastQueriesError);
  }, []);

  return (
    <PastQueryPanelContainer open={isPanelOpen}>
      {pastQueriesError && (
        <QueryPanelTitle>Unable to retrieve past queries</QueryPanelTitle>
      )}
      {!pastQueriesError && pastQueries.length === 0 && (
        <QueryPanelTitle>
          Once you've submitted a query, you'll see it here!
        </QueryPanelTitle>
      )}
      {!pastQueriesError && pastQueries.length > 0 && (
        <>
          <QueryPanelTitle>Past Queries</QueryPanelTitle>
          {pastQueries.map((query, index) => (
            <PastQuery
              key={index}
              queryTitle={limitTextLength(query.title)}
              onClick={() => setQueryId(query.id)}
            />
          ))}
        </>
      )}
    </PastQueryPanelContainer>
  );
};

const QueryHistorySidebar = ({ setQueryId }) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const handleClick = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  return (
    <>
      <Container
        style={{
          width: openWidth,
          height: "100%",
          position: "fixed",
          zIndex: 1,
          left: 0,
          overflowX: "hidden",
          transition: theme.transition.medium,
          padding: "0px",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: isPanelOpen ? "space-between" : "flex-start",
        }}
      >
        <PastQueryPanel isPanelOpen={isPanelOpen} setQueryId={setQueryId} />
        <PanelOpenButton
          onClick={handleClick}
          open={isPanelOpen}
          style={{ order: 1 }}
        >
          <FontAwesomeIcon icon={faClockRotateLeft} />
        </PanelOpenButton>
      </Container>
    </>
  );
};

export default QueryHistorySidebar;
