import React, { useState } from "react";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import Box from "@mui/material/Box";

import theme from "../../themes";

const labels = {
  experience: {
    0.5: "Extremely Unpleasant",
    1: "Very Unpleasant",
    1.5: "Unpleasant",
    2: "Not good",
    2.5: "Okay",
    3: "Decent",
    3.5: "Good",
    4: "Pleasant",
    4.5: "Very Pleasant",
    5: "Remarkable",
  },
  likelihood: {
    0.5: "Extremely Unlikely",
    1: "Very Unlikely",
    1.5: "Unlikely",
    2: "Probably not",
    2.5: "Unsure",
    3: "Possibly",
    3.5: "Somewhat Likely",
    4: "Likely",
    4.5: "Very Likely",
    5: "Certain",
  },
  relevance: {
    0.5: "Completely irrelevant",
    1: "Irrelevant",
    1.5: "Mostly irrelevant",
    2: "Only slightly relevant",
    2.5: "Vaguely relevant",
    3: "Somewhat relevant",
    3.5: "Fairly relevant",
    4: "Relevant",
    4.5: "Almost exactly what I wanted",
    5: "Exactly what I wanted",
  },
};

function StarRatingInput({ labelSet, onChange }) {
  const [value, setValue] = useState(null);
  const [hover, setHover] = useState(-1);
  const relevantLabels = labels[labelSet];
  const handleStarChange = (event, newValue) => {
    setValue(newValue);
    onChange(event);
  };
  const handleHover = (event, hoverValue) => {
    setHover(hoverValue);
  };
  function getLabelText(value) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${relevantLabels[value]}`;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "5px",
      }}
    >
      <Rating
        value={value}
        precision={0.5}
        getLabelText={getLabelText}
        onChange={handleStarChange}
        onChangeActive={handleHover}
        emptyIcon={<StarIcon style={{ opacity: 1 }} fontSize="inherit" />}
      />
      {value !== null && (
        <Box sx={{ ml: 2, color: theme.font.color.dimExtra }}>
          {relevantLabels[hover !== -1 ? hover : value]}
        </Box>
      )}
    </Box>
  );
}

export default StarRatingInput;
