import ApiService from "../services/ApiService";

async function SubmitPasswordReset({
  completedForm,
  setCompleteMessage,
  setErrorMessage,
}) {
  try {
    const response = await ApiService(
      "post",
      "user/password_reset",
      completedForm
    );
    if (response.status === 200) {
      setCompleteMessage("Password reset successful");
    }
  } catch (error) {
    setErrorMessage(error.response.data.status_message);
  }
}

export default SubmitPasswordReset;
