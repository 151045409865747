import axios from "axios";

const Api = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/api",
  timeout: 20000,
});

const ApiService = async (method, endpoint, data = {}, headers = {}) => {
  headers = {
    ...Api.defaults.headers,
    ...headers,
    "Content-Type": "application/json",
  };
  const response = await Api({
    method,
    url: endpoint,
    data,
    headers,
  });
  return response;
};

export default ApiService;
