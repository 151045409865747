import { styled } from "@mui/material/styles";
import theme from "../../themes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FeedbackButton = styled("button")(({ text }) => ({
  zIndex: 10,
  margin: "10px",
  border: `1px solid ${theme.palette.primary}`,
  backgroundColor: theme.palette.background,
  color: theme.palette.primary,
  width: text ? null : "50px",
  height: text ? null : "50px",
  padding: text ? "8px" : null,
  fontSize: text ? theme.font.size.medium : theme.iconSize.medium,
  borderRadius: text ? theme.bubbleBorderRadius : "50%",
  cursor: "pointer",
  "&:hover": {
    transition: theme.transition.fast,
    color: theme.palette.background,
    border: `1px solid ${theme.palette.primary}`,
    backgroundColor: theme.palette.primary,
  },
}));

// please just fill out one of icon or text, not both
// in js
function IconicButton({ handleClick, icon = null, text = null, ...props }) {
  return (
    <FeedbackButton onClick={handleClick} text={text}>
      {icon && <FontAwesomeIcon icon={icon} />}
      {text && <span fontSize={theme.font.size.medium}>{text}</span>}
    </FeedbackButton>
  );
}

export default IconicButton;
