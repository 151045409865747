// for fetching a previously answered question

import ApiService from "../services/ApiService";
import Cookies from "universal-cookie";

const cookies = new Cookies();

async function QAPast(queryId, setQueryResult, setQueryError) {
  let authToken = cookies.get("auth_token");
  try {
    const headers = { Authorization: `Token ${authToken}` };
    const response = await ApiService("get", `query/${queryId}`, {}, headers);
    setQueryResult(response.data);
    if (response.data.error) {
      setQueryError(response.data.result);
    }
  } catch (error) {
    console.error("Error response from query/past:", error.message);
    setQueryError(error.message);
  }
}

export default QAPast;
