// labelSet only relevant for inputType==rating
const GeneralQuestions = {
  "How would you rate your overall experience with GameGuru?": {
    inputType: "rating",
    labelSet: "experience",
  },
  "What features of our platform do you find the most useful and why?": {
    inputType: "free-text",
    labelSet: "experience",
  },
  "What features do you want to see added or improved?": {
    inputType: "free-text",
    labelSet: "experience",
  },
  "How likely are you to recommend GameGuru to others?": {
    inputType: "rating",
    labelSet: "likelihood",
  },
  "Why or why wouldn't you recommend GameGuru to others?": {
    inputType: "free-text",
    labelSet: "experience",
  },
};

const BugQuestions = {
  "Describe where the bug occured and what you were doing.": {
    inputType: "free-text",
    labelSet: "experience",
  },
  "What did you expect to happen?": {
    inputType: "free-text",
    labelSet: "experience",
  },
  "What actually happened?": {
    inputType: "free-text",
    labelSet: "experience",
  },
};

const QAQuestions = {
  "Rate the relevance of the answer to what you asked.": {
    inputType: "rating",
    labelSet: "relevance",
  },
  "Do you have any concerns about the accuracy of the answer?": {
    inputType: "bool",
    labelSet: "experience",
  },
  "Rate the visualisation of the answer.": {
    inputType: "rating",
    labelSet: "experience",
  },
  "Overall, how pleased are you with the platform's response to your question?": {
    inputType: "rating",
    labelSet: "experience",
  },
  "Provide any additional feedback on the answer.": {
    inputType: "free-text",
    labelSet: "experience",
  },
};

export { GeneralQuestions, BugQuestions, QAQuestions };
