import { styled } from "@mui/system";
import { Box, Input } from "@mui/material";
import QueryStyles from "../query/QueryStyles";
import SubmitButton from "../base/SubmitButton";

import theme from "../../themes";

const StyledInput = styled(Input)(({ shadow }) => ({
  ...QueryStyles,
  fontFamily: theme.font.style.primary,
  borderRadius: theme.bubbleBorderRadius,
  color: "white",
  width: "100%",
  border: `1px solid ${theme.palette.backgroundShadeExtra}`,
  marginRight: "20px",
  "&:hover": {
    backgroundColor: theme.palette.backgroundShadeExtra,
    border: `1px solid ${theme.palette.backgroundShadeExtra}`,
    transition: theme.transition.fast,
  },
  "&.Mui-focused": {
    backgroundColor: theme.palette.backgroundShadeExtra,
    border: `1px solid ${theme.palette.backgroundShadeExtra}`,
    boxShadow: shadow,
  },
}));

function PrimaryTextInput({
  onChange,
  onSubmit,
  placeholder = "",
  width = "100%",
  centerText = false,
  createShadow = false,
  textPadding = theme.bubblePadding.medium,
}) {
  const shadow = `0 5px 5px ${theme.palette.primary}`;

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter") {
      onSubmit();
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      width={width}
    >
      <StyledInput
        onKeyDown={handleInputKeyDown}
        onChange={onChange}
        disableUnderline
        placeholder={placeholder}
        shadow={createShadow ? shadow : "none"}
        inputProps={{
          style: {
            textAlign: centerText ? "center" : "left",
            padding: textPadding,
          },
        }}
      />
      <SubmitButton onSubmit={onSubmit} />
    </Box>
  );
}

export default PrimaryTextInput;
