import theme from "../../themes";
import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
} from "recharts";

import generateColorArray from "../../utils/generateColorArray";
import { GetDomain } from "./GetDomain";

function QALineChart({ data, independentVariable, dependentVariables }) {
  const xAxisType = "";
  const chartColors = generateColorArray({
    color0: theme.palette.primary,
    color1: theme.palette.secondary,
  });
  let [gridlines, domainMin, domainMax] = GetDomain({
    data,
    dependentVariables,
  });

  return (
    <ResponsiveContainer width="100%" height={500}>
      <LineChart
        data={data}
        margin={{
          right: 100,
          left: 100,
        }}
      >
        <CartesianGrid
          strokeDasharray="3 3"
          strokeWidth={0.2}
          stroke={theme.font.color.dimExtra}
        />
        <XAxis type={xAxisType} dataKey={independentVariable} />
        <YAxis
          type="number"
          tickFormatter={(value) => parseFloat(value.toFixed(2))}
          ticks={gridlines}
          orientation="left"
        />
        <Legend />
        {dependentVariables.map((dVariable, index) => (
          <Line
            key={index}
            dataKey={dVariable}
            fill={chartColors[index]}
            stroke={chartColors[index]}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
}

export default QALineChart;
