import ApiService from "../services/ApiService";

async function SubmitWelcome({
  email,
  setErrorMessage,
  setEmailFound,
  navigate,
}) {
  try {
    const response = await ApiService("post", "user/welcome", { email });
    if (response.data && response.data.user_exists) {
      setEmailFound(true);
    } else {
      navigate("/register", { state: { email } });
    }
  } catch (error) {
    const status = error.response?.data.status_message
      ? error.response.data.status_message
      : "An error occurred, please try again";
    setErrorMessage(status);
    return false;
  }
}

export default SubmitWelcome;
