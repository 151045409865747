import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

import theme from "../../themes";

const borderRadius = "10px";

const AuthTextField = styled(TextField)({
  marginTop: "0px",
  marginBottom: "20px",
  "& .MuiInputBase-input": {
    backgroundColor: theme.palette.backgroundShade,
    border: `2px solid ${theme.palette.backgroundShadeExtra}`,
    borderRadius: borderRadius,
    color: theme.font.color.primary,
    fontFamily: theme.font.style.primary,
    "&:focus": {
      borderColor: theme.palette.background,
    },
  },
  "& label.Mui-focused": {
    // colour of the text when focused
    color: theme.palette.primary,
    fontFamily: theme.font.style.primary,
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      // shadow-border around the outside
      border: `2px solid ${theme.palette.primary}`,
      borderRadius: borderRadius,
      fontFamily: theme.font.style.primary,
    },
  },
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: theme.font.color.primary,
    backgroundColor: "transparent",
    fontFamily: theme.font.style.primary,
  },
});

export default AuthTextField;
