import { useEffect, useState } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import theme from "../../themes";

const ColoredLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: theme.palette.backgroundShadeExtra,
  },
  barColorPrimary: {
    backgroundColor: theme.palette.primary,
  },
})(LinearProgress);

function StyledProgress({ expectedTime, isComplete }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (isComplete) {
          return 100;
        } else {
          if (prevProgress >= 90) {
            // once progress gets to 90, it starts to increase very slowly
            return prevProgress >= 100
              ? 0
              : prevProgress + 0.5 * (100 - prevProgress);
          }
          return prevProgress >= 100 ? 0 : prevProgress + 10;
        }
      });
    }, expectedTime / 10); // divided by 10 as we are increasing progress by 10%

    return () => {
      clearInterval(timer);
    };
  }, [isComplete, expectedTime]);

  return (
    <>
      <ColoredLinearProgress variant="determinate" value={progress} />
    </>
  );
}

export default StyledProgress;
