import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/system";
import Cookies from "universal-cookie";

import theme from "../../themes";
import ApiService from "../../services/ApiService";
import StyledLogo from "./StyledLogo";
import NavigationSwitch from "./NavigationSwitch";

import FeedbackPanel from "../feedback/FeedbackPanel";

import { ShowFeedbackContext } from "../../pages/AskPage";
import LogoutButton from "../users/LogoutButton";

const cookies = new Cookies();

const Container = styled("div")({
  height: "80px",
  position: "fixed",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  top: 0,
  width: "100%",
  backgroundColor: theme.palette.background,
  zIndex: 100,
});

const StyledLink = styled(Link)({
  textDecoration: "none",
  marginBottom: "auto",
  marginTop: "auto",
});

const UserBubble = styled("div")(({ color }) => ({
  border: `2px solid ${color}`,
  borderRadius: theme.bubbleBorderRadius,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "10px",
  padding: theme.bubblePadding.medium,
  color: theme.font.color.primary,
  fontSize: theme.font.size.medium,
  transition: theme.transition.fast,
  "&:hover": {
    backgroundColor: color,
  },
}));

const UserActionsContainer = styled("div")({
  color: "inherit",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: "20px",
});

const UserActions = () => {
  const [username, setUsername] = useState("");
  const [userColour, setUserColour] = useState("");
  const [userFetchError, setUserFetchError] = useState(false);

  useEffect(() => {
    async function fetchData() {
      let authToken = cookies.get("auth_token");
      try {
        const headers = { Authorization: `Token ${authToken}` };
        const response = await ApiService(
          "get",
          "/user/profile_short",
          {},
          headers
        );
        setUsername(response.data.username);
        setUserColour(response.data.profile_colour);
      } catch (error) {
        setUserFetchError(true);
      }
    }
    fetchData();
  }, []);

  // TODO: update the link to user profile
  return (
    <UserActionsContainer>
      <StyledLink to="/">
        {userFetchError ? null : (
          <UserBubble color={userColour}>{username}</UserBubble>
        )}
      </StyledLink>
      <LogoutButton />
    </UserActionsContainer>
  );
};

const LogoContainer = styled(Link)({
  display: "flex",
  marginLeft: "20px",
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
  textDecoration: "none",
  cursor: "pointer",
});

function PageHeading() {
  const { showFeedback, setShowFeedback } = useContext(ShowFeedbackContext);

  return (
    <>
      <Container>
        <LogoContainer to="/">
          <StyledLogo />
        </LogoContainer>
        <NavigationSwitch />
        <UserActions />
      </Container>
      {showFeedback && <FeedbackPanel />}
    </>
  );
}

export default PageHeading;
