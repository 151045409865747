const theme = {
  palette: {
    background: "#031a29",
    backgroundShade: "#042033",
    backgroundShadeExtra: "#063452",
    primary: "#B6FF9C",
    secondary: "#83F8E9",

    error: "#EE6E6E",
    success: "#A6ED6E",
    action: {
      hoverOpacity: 0.08,
    },
  },
  font: {
    style: {
      primary: "Switzer-Light, Roboto",
      secondary: "Switzer-Medium, Roboto",
    },
    size: {
      small: "12px",
      medium: "16px",
      large: "24px",
    },
    color: {
      primary: "#D2D6D7",
      dim: "#BDBDBD",
      dimExtra: "#8A8A8A",
      primaryContrast: "black",
    },
  },
  iconSize: {
    small: "16px",
    medium: "25px",
    large: "40px",
  },
  transition: {
    fast: "0.2s",
    medium: "0.5s",
    slow: "1s",
  },
  bubblePadding: {
    small: "5px",
    medium: "15px",
    large: "20px",
  },
  bubbleBorderRadius: "25px",
};

export default theme;
