import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@material-ui/core/styles";

import theme from "../../themes";

const StyledTooltip = withStyles({
  tooltip: {
    fontSize: theme.font.size.small,
    fontFamily: theme.font.style.primary,
    color: theme.font.color.primary,
    backgroundColor: theme.palette.backgroundShade,
  },
})(({ placement = "bottom", ...props }) => (
  <Tooltip placement={placement} {...props} />
));

export default StyledTooltip;
