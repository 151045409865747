import { useState } from "react";

import styled from "@emotion/styled";
import theme from "../../themes";

const OptionsContainer = styled("div")({
  alignItems: "left",
  marginTop: "10px",
});

const BooleanButton = styled("button")(({ selected, selectedColor }) => ({
  border: "none",
  backgroundColor: "transparent",
  cursor: "pointer",
  fontSize: theme.font.size.medium,
  "&:hover": {
    color: theme.palette.primary,
  },
  color: selected ? selectedColor : theme.font.color.dimExtra,
  marginRight: "20px",
}));

function BooleanInput({ onChange }) {
  const [value, setValue] = useState(null);

  const handleClick = async (event) => {
    event.preventDefault();
    const value = parseInt(event.currentTarget.dataset.value, 10);
    const result = {
      target: {
        value: value,
      },
    };
    setValue(value === 1);
    onChange(result);
  };

  return (
    <OptionsContainer>
      <BooleanButton
        onClick={handleClick}
        selected={value === true}
        data-value={1}
        selectedColor={theme.palette.error}
      >
        Yes
      </BooleanButton>
      <BooleanButton
        onClick={handleClick}
        selected={value === false}
        data-value={0}
        selectedColor={theme.palette.success}
      >
        No
      </BooleanButton>
    </OptionsContainer>
  );
}

export default BooleanInput;
