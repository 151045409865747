import React from "react";

import StyledLogo from "../base/StyledLogo";

import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import theme from "../../themes";

const AuthBox = styled(Box)({
  width: "auto",
  minWidth: "400px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  marginTop: "auto",
  marginBottom: "auto",
  paddingBottom: "50px",
  fontFamily: theme.font.style.primary,
});

const Title = styled(Typography)({
  color: theme.font.color.primary,
  textAlign: "center",
  margin: "40px 0px",
  fontFamily: theme.font.style.primary,
});

const AuthPanel = ({ children, title }) => {
  return (
    <>
      <AuthBox component="main" maxWidth="xs">
        <StyledLogo
          sx={{
            width: "100px",
            height: "100px",
          }}
        />
        <Title component="h1" variant="h5">
          {title}
        </Title>
        {children}
      </AuthBox>
    </>
  );
};

export default AuthPanel;
