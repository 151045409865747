import { useState, useEffect } from "react";
import theme from "../../themes";
import { styled } from "@mui/system";

const MessageBox = styled("div")({
  color: theme.font.color.primaryContrast,
  fontSize: theme.font.size.medium,
  textAlign: "left",
  paddingLeft: "15px",
  paddingRight: "5px",
  paddingTop: "5px",
  paddingBottom: "5px",
  borderRadius: theme.bubbleBorderRadius,
  width: "60%",
  maxWidth: "600px",
  position: "absolute",
  bottom: "20px",
  zIndex: 100,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const AlertMessage = ({
  message,
  setMessage,
  success = true,
  messageTime = 3000,
}) => {
  const backgroundColor = success ? theme.palette.success : theme.palette.error;
  const [display, setDisplay] = useState(false);

  setTimeout(() => {
    setDisplay(false);
    setMessage("");
  }, messageTime);

  useEffect(() => {
    if (message) {
      setDisplay(true);
    }
  }, [message]);

  return (
    <>
      {display && (
        <>
          <MessageBox
            style={{
              backgroundColor: backgroundColor,
            }}
          >
            {message}
          </MessageBox>
        </>
      )}
    </>
  );
};

export default AlertMessage;
