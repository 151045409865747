import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import PageBackground from "../components/base/PageBackground";
import PasswordResetForm from "../components/users/PasswordResetForm";
import AlertMessage from "../components/base/AlertMessage";
import AuthPanel from "../components/users/AuthPanel";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function PasswordResetPage() {
  const [errorMessage, setErrorMessage] = useState("");
  const [completeMessage, setCompleteMessage] = useState("");
  const navigate = useNavigate();

  let query = useQuery();
  let token = query.get("token");
  let user_id = query.get("user_id");

  useEffect(() => {
    if (!token || !user_id) {
      navigate("/welcome");
    }
  }, [token, user_id, navigate]);

  const [completedForm, setCompletedForm] = useState({
    password1: "",
    password2: "",
    token: token,
    uidb64: user_id,
  });

  useEffect(() => {
    if (completeMessage) {
      setTimeout(() => {
        navigate("/welcome");
      }, 5000);
    }
  }, [completeMessage, navigate]);

  return (
    <PageBackground>
      <AuthPanel title={"Password reset"}>
        <PasswordResetForm
          completedForm={completedForm}
          setCompletedForm={setCompletedForm}
          setErrorMessage={setErrorMessage}
          setCompleteMessage={setCompleteMessage}
        />
        <AlertMessage
          message={errorMessage}
          setMessage={setErrorMessage}
          success={false}
        />
        <AlertMessage
          message={completeMessage}
          setMessage={setCompleteMessage}
          success={true}
        />
      </AuthPanel>
    </PageBackground>
  );
}

export default PasswordResetPage;
