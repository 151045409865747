function isAllInteger({ values }) {
  let isAllInteger = true;
  for (let i = 0; i < values.length; i++) {
    if (!Number.isInteger(values[i])) {
      isAllInteger = false;
      break;
    }
  }
  return isAllInteger;
}
export default isAllInteger;
