import { useRef, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import theme from "../../themes.js";

const StyledDataGrid = styled(DataGrid)({
  "&.MuiDataGrid-root": {
    color: theme.font.color.primary,
    fontFamily: theme.font.style.primary,
    backgroundColor: theme.palette.background,
    borderColor: theme.palette.background,
    borderTop: `0.25px solid ${theme.palette.backgroundShadeExtra}`,
    "--DataGrid-rowBorderColor": theme.palette.backgroundShade,
  },
  "&  .MuiIconButton-root .MuiSvgIcon-root": {
    color: theme.font.color.dimExtra,
  },
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: theme.palette.background,
    color: theme.font.color.dimExtra,
    fontSize: theme.font.size.small,
  },
  "& .MuiDataGrid-row:hover": {
    backgroundColor: theme.palette.primary,
    color: theme.font.color.primaryContrast,
  },
});

function QATable({ data }) {
  // dynamically generate the width of the columns based on the maximum number of characters in each column
  const containerRef = useRef();
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  }, []);

  let maxCharsPerCol = {};
  const rows = data.map((row, i) => {
    let newRows = { id: i };

    for (let key in row) {
      if (row[key] === null) continue;
      if (key.includes(" Url")) {
        maxCharsPerCol[key] = 10;
      } else {
        const numChars = row[key].toString().length;
        if (!maxCharsPerCol[key] || numChars > maxCharsPerCol[key]) {
          maxCharsPerCol[key] = numChars;
        }
      }
      newRows[key] = row[key];
    }
    return newRows;
  });

  const minWidth = (containerWidth / Object.keys(maxCharsPerCol).length) * 0.5;
  const columns = Object.keys(maxCharsPerCol).map((key) => {
    const cleanKey = key.replace(" Url", "");

    return {
      field: key,
      headerName: cleanKey,
      flex: maxCharsPerCol[key],
      minWidth: minWidth,
      renderCell: (cellValues) =>
        key.includes("Url") ? (
          <img
            src={cellValues.value}
            alt={cleanKey}
            style={{ height: "80%", padding: "5px 0px" }}
          />
        ) : (
          cellValues.value
        ),
    };
  });

  return (
    <div
      style={{
        width: "100%",
        maxHeight: 500,
        overflow: "auto",
      }}
      ref={containerRef}
      sx={{
        backgroundColor: theme.palette.background,
      }}
    >
      <StyledDataGrid
        rows={rows}
        columns={columns}
        hideFooter={true}
        autoHeight
      />
    </div>
  );
}

export default QATable;
