import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import theme from "../../themes";

import AuthTextField from "./AuthTextField";
import SubmitButton from "../base/SubmitButton";

const MultiFieldForm = ({
  completedForm,
  setCompletedForm,
  handleFormSubmit,
  minCharacterLimits,
  submitButtonText,
  showEmail = false,
}) => {
  // assumes that the form has a double password field, among others
  // rather than show errors immediately, lets give the user a chance to correct them

  const [showErrors, setShowErrors] = useState({
    username: false,
    password1: false,
    password2: false,
    phone_number: false,
  });
  const [formErrors, setFormErrors] = useState({});
  const [validForm, setValidForm] = useState(false);

  const password2Error = showErrors.password2 || showErrors.passwordMatch;
  const password2HelperText =
    (showErrors["password2"] &&
      `Password should be minimum ${minCharacterLimits.password2} characters`) ||
    (showErrors["passwordMatch"] && "Passwords do not match") ||
    "";

  const onChange = (e) => {
    const newFormData = { ...completedForm, [e.target.name]: e.target.value };
    const newFormErrors = { ...formErrors };

    // password match error
    if (
      (e.target.name === "password1" || e.target.name === "password2") &&
      newFormData.password1 !== "" &&
      newFormData.password2 !== ""
    ) {
      if (newFormData.password1 === newFormData.password2) {
        delete newFormErrors.passwordMatch;
      } else {
        newFormErrors.passwordMatch = true;
      }
    }

    // character limit errors
    if (
      e.target.name in minCharacterLimits &&
      e.target.value.length < minCharacterLimits[e.target.name]
    ) {
      newFormErrors[e.target.name] = true;
    } else {
      delete newFormErrors[e.target.name];
    }

    setCompletedForm(newFormData);
    setFormErrors(newFormErrors);
    setValidForm(
      newFormData.password1 !== "" &&
        newFormData.password2 !== "" &&
        Object.keys(newFormErrors).length === 0
    );
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      let errorFields = {};
      for (let field in formErrors) {
        errorFields[field] = formErrors[field] === true;
      }
      setShowErrors(errorFields);
    }, 1500);
    return () => clearTimeout(timer);
  }, [formErrors]);

  const onKeyDown = (event) => {
    if (event.key === "Enter" && validForm) {
      handleFormSubmit(event);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleFormSubmit}
      noValidate
      sx={{
        mt: 1,
        minWidth: "300px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "0px",
      }}
    >
      {showEmail && (
        <AuthTextField
          onKeyDown={onKeyDown}
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          defaultValue={completedForm.email}
          autoComplete="email"
          disabled
          variant="outlined"
          autoFocus
          InputLabelProps={{
            style: { color: theme.font.color.primary },
          }}
        />
      )}
      <AuthTextField
        onKeyDown={onKeyDown}
        margin="normal"
        required
        fullWidth
        id="password1"
        label="Password"
        name="password1"
        type="password"
        autoComplete="password"
        variant="outlined"
        autoFocus
        error={showErrors.password1}
        InputLabelProps={{
          style: { color: theme.font.color.primary },
        }}
        helperText={
          showErrors.password1 &&
          `Password should be minimum ${minCharacterLimits["password1"]} characters`
        }
        onChange={onChange}
      />
      <AuthTextField
        onKeyDown={onKeyDown}
        margin="normal"
        required
        fullWidth
        id="password2"
        label="Confirm Password"
        name="password2"
        type="password"
        autoComplete="password"
        variant="outlined"
        autoFocus
        onChange={onChange}
        InputLabelProps={{
          style: { color: theme.font.color.primary },
        }}
        error={password2Error}
        helperText={password2HelperText}
      />
      <SubmitButton
        marginTop={"20px"}
        text={submitButtonText}
        onSubmit={handleFormSubmit}
        disabled={!validForm}
      />
    </Box>
  );
};

export default MultiFieldForm;
