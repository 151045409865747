import { useState } from "react";
import { styled } from "@mui/material";
import IconicButton from "../button/IconicButton";

import { faBug } from "@fortawesome/free-solid-svg-icons";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons";

import { GeneralQuestions, BugQuestions } from "./FeedbackQuestions";
import FeedbackForm from "./FeedbackForm";

const FeedbackOptions = [
  {
    title: "General Feedback",
    feedbackType: "general",
    icon: faCommentDots,
    questions: GeneralQuestions,
  },
  {
    title: "Bug Report",
    feedbackType: "bug_report",
    icon: faBug,
    questions: BugQuestions,
  },
];

const PanelContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "absolute",
  bottom: "20px",
  right: "20px",
});

function FeedbackPanel() {
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedQuestions, setSelectedQuestions] = useState({});
  const [feedbackType, setFeedbackType] = useState("");

  const handleOptionSelect = ({ option }) => {
    setSelectedOption(option.title);
    setSelectedQuestions(option.questions);
    setFeedbackType(option.feedbackType);
  };

  const handleCloseForm = () => {
    setSelectedOption("");
    setSelectedQuestions([]);
    setFeedbackType("");
  };

  return (
    <PanelContainer>
      {selectedOption === "" &&
        FeedbackOptions.map((option, index) => (
          <IconicButton
            key={index}
            icon={option.icon}
            handleClick={() => handleOptionSelect({ option })}
          />
        ))}
      {selectedOption !== "" && (
        <FeedbackForm
          title={selectedOption}
          questions={selectedQuestions}
          closeForm={handleCloseForm}
          feedbackType={feedbackType}
        />
      )}
    </PanelContainer>
    // Apply transition?
  );
}

export default FeedbackPanel;
