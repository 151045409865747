import { useState, useEffect } from "react";
import QueryStyles from "./QueryStyles";
import styled from "@emotion/styled";
import theme from "../../themes";

const SuggestedPromptContainer = styled("div")({
  ...QueryStyles,
  width: "30%",
  minHeight: "120px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.backgroundShadeExtra,
    transition: theme.transition.fast,
  },
});

const SuggestedPrompt = ({
  suggestion,
  setQuery,
  submitSuggestion,
  source,
}) => {
  const [submitted, setSubmitted] = useState(false);

  const onClick = async () => {
    await setQuery({
      prompt: suggestion.prompt,
      suggestion_id: suggestion.suggestion_id,
      source: source,
    });
    setSubmitted(true);
  };

  useEffect(() => {
    if (submitted) {
      submitSuggestion();
    }
  }, [submitted]);

  return (
    <SuggestedPromptContainer>
      <div style={{ padding: theme.bubblePadding.medium }} onClick={onClick}>
        {suggestion.prompt}
      </div>
    </SuggestedPromptContainer>
  );
};

export default SuggestedPrompt;
