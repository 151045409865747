import MultiFieldForm from "./MultiFieldForm";
import SubmitRegisterVerify from "../../endpoints/SubmitRegisterVerify";

const MIN_CHARATER_LIMITS = {
  username: 5,
  password1: 8,
  password2: 8,
};

const RegisterForm = ({
  completedForm,
  setCompletedForm,
  setFormStep,
  setErrorMessage,
}) => {
  const handleRegisterFormSubmit = async (event) => {
    event.preventDefault();
    const success = await SubmitRegisterVerify(completedForm, setErrorMessage);
    if (success) {
      setFormStep(1);
    }
  };

  return (
    <MultiFieldForm
      completedForm={completedForm}
      setCompletedForm={setCompletedForm}
      handleFormSubmit={handleRegisterFormSubmit}
      minCharacterLimits={MIN_CHARATER_LIMITS}
      showEmail={true}
    />
  );
};

export default RegisterForm;
export { MIN_CHARATER_LIMITS };
