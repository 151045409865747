import { useState } from "react";

import Cookies from "universal-cookie";
import { styled } from "@mui/system";

import ApiService from "../../services/ApiService";
import SubtleButton, { StyledIcon } from "../button/SubtleButton";
import { useNavigate } from "react-router-dom";

import {
  faPersonThroughWindow,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import theme from "../../themes";

const cookies = new Cookies();

const LogoutWarningBackground = styled("div")({
  position: "fixed",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  backgroundColor: theme.palette.background,
  opacity: 0.95,
  zIndex: 100,
});

const LogoutWarningMessage = styled("div")({
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: theme.font.color.primary,
  fontSize: theme.font.size.medium,
  textAlign: "center",
  borderRadius: theme.bubbleBorderRadius,
  padding: "50px",
  zIndex: 101,
});

const LogoutOptionsContainer = styled("div")({
  justifyContent: "center",
  width: "100%",
  marginTop: "20px",
});

function LogoutButton() {
  const [logoutWarning, setLogoutWarning] = useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    async function logout() {
      let authToken = cookies.get("auth_token");
      try {
        const headers = { Authorization: `Token ${authToken}` };
        await ApiService("post", "/user/logout", {}, headers);
        cookies.remove("auth_token");
        navigate("/welcome");
      } catch (error) {
        console.error("Logout failed", error);
      }
    }
    logout();
  };

  const handleLogoutClick = () => {
    setLogoutWarning(true);
  };

  return (
    <>
      <SubtleButton
        tooltipText="logout"
        icon={faPersonThroughWindow}
        handleClick={handleLogoutClick}
      />
      {logoutWarning && (
        <LogoutWarningBackground>
          <LogoutWarningMessage>
            Are you sure you want to logout?
            <LogoutOptionsContainer>
              <StyledIcon
                icon={faCheck}
                onClick={handleLogout}
                sx={{ marginRight: "20px" }}
              />
              <StyledIcon
                icon={faTimes}
                onClick={() => setLogoutWarning(false)}
                sx={{ marginRight: "20px" }}
              />
            </LogoutOptionsContainer>
          </LogoutWarningMessage>
        </LogoutWarningBackground>
      )}
    </>
  );
}

export default LogoutButton;
