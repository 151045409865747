import theme from "../../themes";
import { styled } from "@mui/system";

const StyledText = styled("a")({
  color: theme.palette.primary,
  textDecoration: "none",
  cursor: "pointer",
  fontSize: theme.font.size.small,
  margin: 20,
  "&:hover": {
    color: theme.palette.secondary,
  },
});

const TextLink = ({ text, onClick }) => {
  return <StyledText onClick={onClick}>{text}</StyledText>;
};

export default TextLink;
