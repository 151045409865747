import theme from "../../themes";

const DisclaimerMessage = ({ text, links }) => {
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        textAlign: "center",
        padding: "5px",
        fontSize: theme.font.size.small,
        color: theme.font.color.dim,
      }}
    >
      {text}
      {Object.keys(links).map((key, index) => {
        return (
          <a
            key={index}
            href={links[key]}
            style={{
              color: theme.font.color.dim,
              marginLeft: "5px",
            }}
          >
            {key}
          </a>
        );
      })}
    </div>
  );
};

export default DisclaimerMessage;
