import ApiService from "../services/ApiService";
import Cookies from "universal-cookie";

const cookies = new Cookies();

async function SubmitRegistration({
  completedForm,
  setErrorMessage,
  navigate,
}) {
  try {
    const response = await ApiService("post", "user/register", completedForm);
    if (response.status === 200) {
      // store token
      const authToken = response.data.auth_token;
      cookies.set("auth_token", authToken, {
        path: "/",
        secure: true,
      });
      navigate("/ask");
    }
  } catch (error) {
    setErrorMessage(error.response.data.status_message);
    return false;
  }
}

export default SubmitRegistration;
