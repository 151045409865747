import React, { createContext, useState } from "react";

import StyledBackground from "../components/base/PageBackground";
import PageHeading from "../components/base/PageHeading";

import QueryHistorySidebar from "../components/query/QueryHistorySidebar";
import QueryPanel from "../components/query/QueryPanel";

import Header from "../components/Header";

export const ShowFeedbackContext = createContext();

function AskPage() {
  const [showFeedback, setShowFeedback] = useState(true);
  const [queryId, setQueryId] = useState(null);

  return (
    <>
      <Header subtitle="Ask" />
      <StyledBackground>
        <ShowFeedbackContext.Provider value={{ showFeedback, setShowFeedback }}>
          <PageHeading />
          <QueryHistorySidebar setQueryId={setQueryId} />
          <QueryPanel queryId={queryId} />
        </ShowFeedbackContext.Provider>
      </StyledBackground>
    </>
  );
}

export default AskPage;
