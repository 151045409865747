import ApiService from "../services/ApiService";
import Cookies from "universal-cookie";

const cookies = new Cookies();

async function ValidateToken({ setIsValidAuthToken, setIsLoading }) {
  try {
    const auth_token = cookies.get("auth_token");
    if (!auth_token) {
      setIsValidAuthToken(false);
      return;
    }
    const response = await ApiService(
      "get",
      `token/verify?auth_token=${auth_token}`
    );
    if (response.status === 200) {
      setIsValidAuthToken(true);
    } else {
      setIsValidAuthToken(false);
    }
  } catch (error) {
    setIsValidAuthToken(false);
  } finally {
    setIsLoading(false);
  }
}

export default ValidateToken;
