import SingleFieldForm from "./SingleFieldForm";
import SubmitRegistration from "../../endpoints/SubmitRegistration";

const VerificationCodeForm = ({
  completedForm,
  setCompletedForm,
  navigate,
  setErrorMessage,
}) => {
  const handleVerificationFormSubmit = async (event) => {
    event.preventDefault();
    SubmitRegistration({ completedForm, setErrorMessage, navigate });
  };

  const handleVerificationFormChange = async (event) => {
    event.preventDefault();
    const formWithVerificationCode = {
      ...completedForm,
      verification_code: event.target.value,
    };
    setCompletedForm(formWithVerificationCode);
  };

  return (
    <SingleFieldForm
      onSubmit={handleVerificationFormSubmit}
      onChange={handleVerificationFormChange}
      label={"Verification Code"}
      autoComplete={"verificationCode"}
      inputProps={{
        maxLength: 6,
        pattern: "[0-9]{6}",
      }}
    />
  );
};

export default VerificationCodeForm;
