import { styled } from "@mui/system";
import StyledTooltip from "../base/StyledTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import theme from "../../themes";

const StyledIcon = styled(FontAwesomeIcon)({
  marginLeft: "20px",
  color: theme.palette.primary,
  fontSize: theme.iconSize.medium,
  "&:hover": {
    cursor: "pointer",
    color: theme.palette.secondary,
  },
});

function SubtleButton({ tooltipText, icon, handleClick }) {
  return (
    <StyledTooltip title={tooltipText}>
      <StyledIcon icon={icon} onClick={handleClick} />
    </StyledTooltip>
  );
}

export default SubtleButton;
export { StyledIcon };
