import isAllInteger from "../../utils/isAllInteger";

const domainPadding = 0.05;
const numberGridlines = 5;

function GetDomain({ data, dependentVariables }) {
  const allData = data.flatMap((d) => dependentVariables.map((v) => d[v]));
  let domainMin = Math.min(...allData) * (1 - domainPadding);
  let domainMax = Math.max(...allData) * (1 + domainPadding);

  // if all Integer values, make the ticks integers
  const allIntegerValues = isAllInteger(allData);
  let interval = (domainMax - domainMin) / numberGridlines;
  if (allIntegerValues) {
    interval = Math.ceil(interval);
    domainMin = Math.floor(domainMin);
    domainMax = Math.ceil(domainMax);
  }
  let gridlines = [];
  for (let i = domainMin; i <= domainMax; i += interval) {
    gridlines.push(i);
  }

  return [gridlines, domainMin, domainMax];
}

export { GetDomain };
