import MultiFieldForm from "./MultiFieldForm";
import SubmitPasswordReset from "../../endpoints/SubmitPasswordReset";

const PasswordResetForm = ({
  completedForm,
  setCompletedForm,
  setErrorMessage,
  setCompleteMessage,
}) => {
  const minCharacterLimits = {
    password1: 8,
    password2: 8,
  };
  const handlePasswordResetFormSubmit = async (event) => {
    event.preventDefault();
    await SubmitPasswordReset({
      completedForm,
      setErrorMessage,
      setCompleteMessage,
    });
  };

  return (
    <MultiFieldForm
      completedForm={completedForm}
      setCompletedForm={setCompletedForm}
      handleFormSubmit={handlePasswordResetFormSubmit}
      minCharacterLimits={minCharacterLimits}
      showEmail={false}
    />
  );
};

export default PasswordResetForm;
