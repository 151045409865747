import React, { useState, useContext, useEffect } from "react";

import Box from "@mui/material/Box";

import QAPast from "../../endpoints/QAPast";
import SubmitQA from "../../endpoints/SubmitQA";
import QueryPanelQuestion from "./QueryPanelQuestion";
import QueryPanelResult from "./QueryPanelResult";
import { ShowFeedbackContext } from "../../pages/AskPage";

function QueryPanel({ queryId }) {
  const [query, setQuery] = useState({});
  const [queryError, setQueryError] = useState("");
  const [querySubmitted, setQuerySubmitted] = useState(false);
  const [queryResult, setQueryResult] = useState(null);

  const { showFeedback, setShowFeedback } = useContext(ShowFeedbackContext);

  const handleQuerySubmit = async () => {
    if (query !== "") {
      SubmitQA(query, setQueryResult, setQueryError);
      setQuerySubmitted(true);
      setShowFeedback(false);
    }
  };

  useEffect(() => {
    const fetchPastQA = async () => {
      if (queryId) {
        await QAPast(queryId, setQueryResult, setQueryError);
      }
    };
    fetchPastQA();
  }, [queryId]);

  useEffect(() => {
    if (queryResult) {
      setQuery({
        prompt: queryResult.prompt,
        source: queryResult.source,
      });
      setQuerySubmitted(true);
      setShowFeedback(false);
    }
  }, [queryResult]);

  const newPrompt = () => {
    setQuerySubmitted(false);
    setQuery({});
    setQueryResult(null);
    setQueryError("");
    setShowFeedback(true);
  };

  return (
    <Box
      component="section"
      width={{ xs: "80%", sm: "800px" }}
      height="100%"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        marginTop: "80px",
      }}
    >
      {querySubmitted ? (
        <QueryPanelResult
          prompt={query.prompt}
          result={queryResult}
          queryError={queryError}
          setQueryError={setQueryError}
          newPrompt={newPrompt}
        />
      ) : (
        <QueryPanelQuestion
          setQuery={setQuery}
          handleQuerySubmit={handleQuerySubmit}
        />
      )}
    </Box>
  );
}

export default QueryPanel;
