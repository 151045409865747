import { styled } from "@mui/system";
import StyledTooltip from "./StyledTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import theme from "../../themes";

const DisabledText = "Coming soon!";

const SwitchContainer = styled("div")(
  ({ containerHeight, containerWidth }) => ({
    height: containerHeight,
    borderRadius: theme.bubbleBorderRadius,
    display: "flex",
    alignItems: "center",
    border: `1px solid ${theme.palette.backgroundShadeExtra}`,
    zIndex: 5,
    width: containerWidth,
    marginTop: "auto",
    marginBottom: "auto",
  })
);

const SwitchButton = styled("div")(
  ({ disabled, buttonWidth, horizontalMargin }) => ({
    borderRadius: theme.bubbleBorderRadius,
    color: theme.font.color.primary,
    width: buttonWidth,
    marginLeft: horizontalMargin,
    marginRight: horizontalMargin,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      color: !disabled ? theme.palette.primary : theme.font.color.primary,
      cursor: !disabled ? "pointer" : "default",
    },
  })
);

const SelectedButton = styled("div")(
  ({
    buttonWidth,
    horizontalMargin,
    selectedButtonHeight,
    selectedButtonVerticalMargin,
  }) => ({
    borderRadius: theme.bubbleBorderRadius,
    color: theme.font.color.primaryContrast,
    width: buttonWidth,
    marginLeft: horizontalMargin,
    marginRight: horizontalMargin,
    marginTop: selectedButtonVerticalMargin,
    marginBottom: "auto",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    backgroundColor: theme.palette.primary,
    height: selectedButtonHeight,
    zIndex: 10,
    position: "relative",
    top: -1,
  })
);

const Switch = ({
  selectedButton,
  setSelectedButton,
  options,
  containerHeight,
  selectedButtonHeight,
  buttonWidth,
  horizontalMargin,
  selectedButtonVerticalMargin,
}) => {
  const containerWidth = options.length * buttonWidth + 2 * horizontalMargin;

  const handleClick = (option) => {
    if (option.disabled) {
      return;
    }
    setSelectedButton(option.name);
  };

  return (
    <SwitchContainer
      containerHeight={containerHeight}
      containerWidth={containerWidth}
    >
      {options.map((option, index) => {
        const content = option.icon ? (
          <FontAwesomeIcon key={index} icon={option.icon} />
        ) : (
          option.name
        );
        return selectedButton === option.name ? (
          <SelectedButton
            key={index}
            buttonWidth={buttonWidth}
            horizontalMargin={horizontalMargin}
            selectedButtonHeight={selectedButtonHeight}
            selectedButtonVerticalMargin={selectedButtonVerticalMargin}
          >
            {content}
          </SelectedButton>
        ) : (
          <StyledTooltip
            key={index}
            title={option.disabled ? DisabledText : ""}
          >
            <SwitchButton
              key={index}
              onClick={() => handleClick(option)}
              disabled={option.disabled}
              buttonWidth={buttonWidth}
              horizontalMargin={horizontalMargin}
            >
              {content}
            </SwitchButton>
          </StyledTooltip>
        );
      })}
    </SwitchContainer>
  );
};

export default Switch;
