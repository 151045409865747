import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import AuthPanel from "../components/users/AuthPanel";
import PageBackground from "../components/base/PageBackground";
import AlertMessage from "../components/base/AlertMessage";
import SingleFieldForm from "../components/users/SingleFieldForm";
import TextLink from "../components/base/TextLink";

import SubmitLogin from "../endpoints/SubmitLogin";
import SubmitWelcome from "../endpoints/SubmitWelcome";
import SubmitPasswordResetRequest from "../endpoints/SubmitPasswordResetRequest";
import ValidateUser from "../endpoints/ValidateUser";
import ValidateToken from "../endpoints/ValidateToken";

function WelcomePage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [emailFound, setEmailFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isValidAuthToken, setIsValidAuthToken] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // check if there is a valid auth token
    ValidateToken({ setIsValidAuthToken, setIsLoading });
    if (!isLoading && isValidAuthToken) {
      navigate("/ask");
    }

    // check if the user is logged in in the backend already
    ValidateUser({ navigate });
  }, [navigate, isValidAuthToken, isLoading]);

  const handleEmailSubmit = async (event) => {
    event.preventDefault();
    SubmitWelcome({ email, setErrorMessage, setEmailFound, navigate });
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    SubmitLogin({ email, password, setErrorMessage, navigate });
  };

  const handlePasswordResetSubmit = async (event) => {
    event.preventDefault();
    SubmitPasswordResetRequest({ email, setSuccessMessage, setErrorMessage });
  };

  return (
    <PageBackground>
      {!emailFound && (
        <AuthPanel title={"Welcome to Gameguru"}>
          <SingleFieldForm
            onSubmit={handleEmailSubmit}
            onChange={(event) => setEmail(event.target.value)}
            label={"Your Email Address"}
            autoComplete={"email"}
          />
        </AuthPanel>
      )}
      {emailFound && (
        <AuthPanel title={"Welcome back to Gameguru"}>
          <SingleFieldForm
            onSubmit={handleLogin}
            onChange={(event) => setPassword(event.target.value)}
            label={"Your Password"}
            autoComplete={"current-password"}
            submitButtonText="Log In"
            isPasswordField={true}
          />
          <TextLink
            text={"Password reset"}
            onClick={handlePasswordResetSubmit}
          />
        </AuthPanel>
      )}
      {errorMessage !== "" && (
        <AlertMessage
          message={errorMessage}
          setMessage={setErrorMessage}
          success={false}
          messageTime={5000}
        />
      )}
      {successMessage !== "" && (
        <AlertMessage
          message={successMessage}
          setMessage={setSuccessMessage}
          success={true}
          messageTime={5000}
        />
      )}
    </PageBackground>
  );
}

export default WelcomePage;
