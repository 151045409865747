import { ReactComponent as Logo } from "../../assets/logo.svg";
import { styled } from "@mui/system";
import theme from "../../themes";

const StyledLogo = styled(Logo)({
  position: "relative",
  height: "50%",
  width: "auto",
  "& g": {
    fill: theme.palette.primary,
    maxWidth: "100%",
    maxHeight: "100%",
  },
});

export default StyledLogo;
