import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import theme from "../../themes";
import { styled } from "@mui/material/styles";

function SubmitButton({
  onSubmit,
  text = "",
  marginTop = "0px",
  disabled = false,
}) {
  // the marginTop arg is stupid but can't figure out how to get it to work in sx{{}}

  const StyledButton = styled(Button)({
    borderRadius: theme.bubbleBorderRadius,
    height: "100%",
    fontSize: theme.iconSize.large,
    backgroundColor: theme.palette.primary,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: marginTop,
    border: `2px solid ${theme.palette.primary}`,
    color: theme.palette.background,
    "&:hover": {
      backgroundColor: theme.palette.secondary,
      border: `2px solid ${theme.palette.secondary}`,
      transition: theme.transition.fast,
    },
    "&.Mui-focusVisible": {
      backgroundColor: theme.palette.secondary,
    },
    "& span": {
      margin: "0px",
    },
    "&:disabled": {
      backgroundColor: "transparent",
      color: theme.font.color.primary,
      border: `2px solid ${theme.font.color.primary}`,
      transition: theme.transition.fast,
    },
  });

  if (text !== "") {
    return (
      <StyledButton
        onClick={onSubmit}
        variant="contained"
        disabled={disabled}
        sx={{
          fontSize: theme.font.size.small,
        }}
      >
        {text}
      </StyledButton>
    );
  } else {
    return (
      <StyledButton
        onClick={onSubmit}
        variant="contained"
        disabled={disabled}
        endIcon={<SendIcon />}
      />
    );
  }
}

export default SubmitButton;
