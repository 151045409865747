import React, { useState, useEffect } from "react";
import theme from "../../themes";
import Cookies from "universal-cookie";

import { styled, darken } from "@mui/material";

import BooleanInput from "./BooleanInput";
import StarRatingInput from "./StarRatingInput";
import ResizingTextInput from "./ResizingTextInput";
import SubmitButton from "../base/SubmitButton";
import ApiService from "../../services/ApiService";

const cookies = new Cookies();

const FormContainer = styled("div")(({ minWidth }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
  position: "absolute",
  bottom: "20px",
  right: "20px",
  minWidth: minWidth,
  paddingTop: "20px",
  paddingBottom: "20px",
  paddingLeft: "30px",
  paddingRight: "30px",
  borderRadius: theme.bubbleBorderRadius,
  boxShadow: `2px 2px 6px 0px ${darken(theme.palette.background, 0.2)}`,
  backgroundColor: theme.palette.backgroundShade,
  zIndex: "100",
}));

const Title = styled("div")({
  color: theme.palette.primary,
  fontSize: theme.font.size.medium,
  textAlign: "left",
  marginTop: "5px",
});

const Subtitle = styled("div")({
  color: theme.font.color.primary,
  fontSize: theme.font.size.small,
  textAlign: "left",
  marginTop: "5px",
  marginBottom: "20px",
});

const CloseButton = styled("button")({
  position: "absolute",
  top: "10px",
  right: "20px",
  cursor: "pointer",
  background: "transparent",
  color: theme.palette.backgroundShadeExtra,
  border: "none",
  fontSize: theme.font.size.medium,
  padding: "5px",
});

const QuestionText = styled("div")({
  width: "100%",
  marginTop: "20px",
  marginBottom: "5px",
  color: theme.font.color.primary,
  boxSizing: "border-box",
});

function FreeTextQuestion({ question, onChange }) {
  return (
    <>
      <QuestionText>{question}</QuestionText>
      <ResizingTextInput onChange={onChange} />
    </>
  );
}

function StarRatingQuestion({ question, onChange, labelSet }) {
  return (
    <>
      <QuestionText>{question}</QuestionText>
      <StarRatingInput onChange={onChange} labelSet={labelSet} />
    </>
  );
}

function BooleanQuestion({ question, onChange }) {
  return (
    <>
      <QuestionText>{question}</QuestionText>
      <BooleanInput onChange={onChange} />
    </>
  );
}

function Question({ question, questionConfig, handleInputChange }) {
  const onChange = (event) => {
    handleInputChange(event, question);
  };

  return (
    <>
      {questionConfig.inputType === "free-text" && (
        <FreeTextQuestion question={question} onChange={onChange} />
      )}
      {questionConfig.inputType === "rating" && (
        <StarRatingQuestion
          question={question}
          onChange={onChange}
          labelSet={questionConfig.labelSet}
        />
      )}
      {questionConfig.inputType === "bool" && (
        <BooleanQuestion question={question} onChange={onChange} />
      )}
    </>
  );
}

function Form({
  questions,
  setFormSubmitted,
  feedbackType,
  additionalFeedbackData = {},
}) {
  const [feedbackAnswers, setFormData] = useState({});

  // pre-populate all the answers with empty strings
  useEffect(() => {
    const initialFormData = {};
    Object.keys(questions).forEach((key) => {
      initialFormData[key] = "";
    });
    setFormData(initialFormData);
  }, [questions]);

  const handleInputChange = (event, question) => {
    setFormData({
      ...feedbackAnswers,
      [question]: event.target.value,
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    let authToken = cookies.get("auth_token");
    try {
      const headers = { Authorization: `Token ${authToken}` };
      const data = {
        feedback_type: feedbackType,
        detailed: feedbackAnswers,
        submitted_on_page: window.location.pathname,
        ...additionalFeedbackData,
      };
      const response = await ApiService(
        "POST",
        "/feedback/submit",
        data,
        headers
      );
      if (response.status === 201) {
        setFormSubmitted(true);
      }
    } catch (error) {
      console.error("Submission error: ", error);
    }
  };

  return (
    <form>
      {Object.keys(questions).map((question, index) => (
        <Question
          key={index}
          question={question}
          questionConfig={questions[question]}
          handleInputChange={handleInputChange}
        />
      ))}
      <SubmitButton text="submit" marginTop="10px" onSubmit={onSubmit} />
    </form>
  );
}

const TypeToFormWidth = {
  general: "500px",
  bug_report: "300px",
  other: "300px",
};

function getFormWidth(feedbackType) {
  if (!feedbackType) {
    return TypeToFormWidth["other"];
  }
  return TypeToFormWidth[feedbackType];
}

function FeedbackForm({
  title,
  questions,
  closeForm,
  feedbackType,
  additionalFeedbackData = {},
}) {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const formWidth = getFormWidth(feedbackType);

  useEffect(() => {
    if (formSubmitted) {
      const timer = setTimeout(() => {
        closeForm();
      }, 4000);
    }
  }, [formSubmitted]);

  return (
    <>
      <FormContainer minWidth={formWidth}>
        {formSubmitted && (
          <>
            <Title>Thank you for your feedback!</Title>
            <Subtitle>
              Thank you for giving up some time to improve the platform.
              <br />
              Our team appreciates every piece of feedback.
            </Subtitle>
          </>
        )}
        {!formSubmitted && (
          <>
            <CloseButton onClick={closeForm}>x</CloseButton>
            <Title>{title}</Title>
            <Subtitle>Thanks for helping to improve this platform.</Subtitle>
            <Form
              questions={questions}
              setFormSubmitted={setFormSubmitted}
              feedbackType={feedbackType}
              additionalFeedbackData={additionalFeedbackData}
            />
          </>
        )}
      </FormContainer>
    </>
  );
}

export default FeedbackForm;
