import React, { useState, useEffect } from "react";
import Switch from "./Switch";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";

const Options = [
  { name: "ask", disabled: false, path: "/ask" },
  { name: "explore", disabled: true, path: "/explore" },
];

const ContainerHeight = 45;
const SelectedButtonHeight = 35;
const ButtonWidth = 80;
const HorizontalMargin = 5;
const SelectedButtonVerticalMargin = 5;

const Container = styled("div")({
  position: "absolute",
  left: "50%",
  transform: "translateX(-50%)",
});

const NavigationSwitch = () => {
  const [selectedButton, setSelectedButton] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const pathName = window.location.pathname.substring(1);
    const selectedOption = Options.find(
      (option) => option.path.substring(1) === pathName
    );
    if (selectedOption) {
      setSelectedButton(selectedOption.name);
    }
  }, []);

  useEffect(() => {
    if (selectedButton) {
      navigate(Options.find((option) => option.name === selectedButton).path);
    }
  }, [selectedButton, navigate]);

  return (
    <Container>
      <Switch
        selectedButton={selectedButton}
        setSelectedButton={setSelectedButton}
        options={Options}
        // visualisation stuff
        containerHeight={ContainerHeight}
        buttonWidth={ButtonWidth}
        horizontalMargin={HorizontalMargin}
        selectedButtonHeight={SelectedButtonHeight}
        selectedButtonVerticalMargin={SelectedButtonVerticalMargin}
      />
    </Container>
  );
};

export default NavigationSwitch;
