import React from "react";
import Box from "@mui/material/Box";

import theme from "../../themes";

const PageBackground = (props) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      bgcolor={theme.palette.background}
      fontFamily={theme.font.style.primary}
      minHeight="100vh"
      height="auto"
      overflow="auto"
    >
      {props.children}
    </Box>
  );
};

export default PageBackground;
