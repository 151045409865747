import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

class Header extends React.Component {
  render() {
    let title =
      this.props.subtitle !== ""
        ? `Gameguru | ${this.props.subtitle}`
        : "Gameguru";
    return (
      <HelmetProvider>
        <Helmet>
          <title>{title}</title>
        </Helmet>
      </HelmetProvider>
    );
  }
}

export default Header;
