import ApiService from "../services/ApiService";
import Cookies from "universal-cookie";

const cookies = new Cookies();

async function QAHistory(setQAHistory, setQueryError) {
  let authToken = cookies.get("auth_token");
  try {
    const headers = { Authorization: `Token ${authToken}` };
    const response = await ApiService("get", "query/history", {}, headers);
    setQAHistory(response.data);
  } catch (error) {
    console.error("Error response from query/history:", error.message);
    setQueryError(true);
  }
}

export default QAHistory;
