import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import StealthLandingPage from "./pages/StealthLandingPage";
import WelcomePage from "./pages/WelcomePage";
import RegisterPage from "./pages/RegisterPage";
import AskPage from "./pages/AskPage";

import PrivateRoute from "./services/PrivateRoute";
import PasswordResetPage from "./pages/PasswordResetPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="" element={<StealthLandingPage />} />
        <Route path="/welcome" element={<WelcomePage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/password-reset" element={<PasswordResetPage />} />
        <Route
          path="/ask"
          element={
            <PrivateRoute>
              <AskPage />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
