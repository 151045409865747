import { styled } from "@mui/system";

import theme from "../../themes";
import DisclaimerMessage from "../base/DisclaimerMessage";
import PrimaryTextInput from "../base/PrimaryTextInput";

const InputPlaceholder = "What can Gameguru help you with?";

// TODO update this
const DisclaimerMessageText =
  "Gameguru is still in beta testing and so may generate incorrect or incomplete answers.";
const DisclaimerMessageLink = { "Find out more": "https://www.google.com/" };

const Container = styled("div")({
  position: "absolute",
  bottom: "0px",
  paddingBottom: "20px",
  width: "100%",
  maxWidth: "800px",
  backgroundColor: theme.palette.background,
});

const QueryInput = ({ onChange, onSubmit }) => {
  return (
    <Container>
      <PrimaryTextInput
        onChange={onChange}
        onSubmit={onSubmit}
        placeholder={InputPlaceholder}
      />
      <DisclaimerMessage
        text={DisclaimerMessageText}
        links={DisclaimerMessageLink}
      />
    </Container>
  );
};

export default QueryInput;
