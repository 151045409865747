import { React, useState, useEffect } from "react";
import theme from "../../themes.js";
import styled from "@emotion/styled";

import QABarChart from "../visualisation/QABarChart.js";
import QALineChart from "../visualisation/QALineChart.js";
import QATable from "../visualisation/QATable.js";
import { camelCaseToCapitalised } from "../../utils/textFormatting.js";

import QueryActionsBar from "./QueryActionsBar.js";

const DirectAnswer = styled("div")({
  textAlign: "left",
  color: theme.font.color.primary,
  margin: "15px 0px",
});

const ChartContainer = styled("div")({
  width: "100%",
});

function ContextVisualisation({
  data,
  chartType,
  independentVariables,
  dependentVariables,
  showChart,
  tableOnly,
}) {
  const capitalizedIndependentVariables = independentVariables.map((iv) =>
    camelCaseToCapitalised(iv)
  );
  const capitalizedDependentVariables = dependentVariables.map((dv) =>
    camelCaseToCapitalised(dv)
  );
  const titledData = data.map((d) => {
    const newD = {};
    for (const key in d) {
      // if there is a . , remove the bit before it
      const new_key = key.split(".").pop();
      newD[camelCaseToCapitalised(new_key)] = d[key];
    }
    for (const key in newD) {
      if (typeof newD[key] === "number") {
        newD[key] = Math.round(newD[key] * 100) / 100;
      }
    }
    return newD;
  });

  return (
    <ChartContainer>
      {showChart && !tableOnly ? (
        chartType === "bar" ? (
          <QABarChart
            data={titledData}
            independentVariable={capitalizedIndependentVariables[0]}
            dependentVariables={capitalizedDependentVariables}
          />
        ) : (
          <QALineChart
            data={titledData}
            independentVariable={capitalizedIndependentVariables[0]}
            dependentVariables={capitalizedDependentVariables}
          />
        )
      ) : (
        <QATable data={titledData} />
      )}
    </ChartContainer>
  );
}

const VisualisationContainer = styled("div")({
  position: "relative",
  justifyContent: "center",
  width: "100%",
});

function QueryAnswer({ result }) {
  const tableOnly =
    !result.error &&
    (result.chart_type === "table" ||
      result.independent_variables.length === 0 ||
      result.dependent_variables.length === 0)
      ? true
      : false;
  // for toggling between a chart and table view, for data
  // which can be visulised.
  // For tableOnly data, its state is irrelevant
  const [showChart, setShowChart] = useState(true);
  useEffect(() => {
    if (tableOnly) {
      setShowChart(false);
    }
  }, [tableOnly]);

  const qaSummary = {
    query_id: result.query_id,
    summary: null,
    made_manual_request: false, // TODO: at some point connect this
  };

  return (
    <>
      <DirectAnswer>{result.answer}</DirectAnswer>
      {!result.error && (
        <>
          {result.context_result.length > 0 && (
            <VisualisationContainer>
              <ContextVisualisation
                style={{ position: "relative" }}
                data={result.context_result}
                chartType={result.chart_type}
                independentVariables={result.independent_variables}
                dependentVariables={result.dependent_variables}
                showChart={showChart}
                tableOnly={tableOnly}
              />
            </VisualisationContainer>
          )}
          <QueryActionsBar
            isChartOption={!tableOnly}
            showChart={showChart}
            setShowChart={setShowChart}
            qaSummary={qaSummary}
          />
        </>
      )}
    </>
  );
}

export default QueryAnswer;
