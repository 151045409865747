import { useState, useEffect } from "react";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";

import theme from "../../themes.js";

import QueryInput from "./QueryInput.js";
import SuggestedPrompt from "./SuggestedPrompt.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTrendUp, faLightbulb } from "@fortawesome/free-solid-svg-icons";
import ApiService from "../../services/ApiService.js";

const SectionTitle = styled("div")({
  color: theme.font.color.primary,
  fontSize: theme.font.size.large,
  textAlign: "left",
  padding: "0.5rem 0",
  letterSpacing: "-0.03em",
  width: "100%",
});

const SectionTitleIcon = styled(FontAwesomeIcon)({
  marginRight: "0.8rem",
  color: theme.palette.primary,
});

const FetchPrompts = async ({ endpoint, setPrompts, setQueryError }) => {
  try {
    const response = await ApiService("get", endpoint);
    setPrompts(response?.data.prompts);
  } catch (error) {
    console.error(`Error response from ${endpoint}:`, error.message);
    setQueryError(true);
  }
};

function SuggestedPrompts({ handleQuerySubmit, setQuery, endpoint }) {
  const [queryError, setQueryError] = useState(false);
  const [suggestedPrompts, setSuggestedPrompts] = useState([]);

  useEffect(() => {
    FetchPrompts({
      endpoint: endpoint,
      setPrompts: setSuggestedPrompts,
      setQueryError: setQueryError,
    });
  }, []);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 2 }}
    >
      {suggestedPrompts.map((suggestedPrompt, index) => (
        <SuggestedPrompt
          key={index}
          suggestion={suggestedPrompt}
          submitSuggestion={handleQuerySubmit}
          setQuery={setQuery}
          source={endpoint}
        />
      ))}
    </Box>
  );
}

const QueryPanelQuestion = ({ setQuery, handleQuerySubmit }) => {
  return (
    <>
      <SectionTitle>
        <SectionTitleIcon icon={faArrowTrendUp} />
        Trending Queries
      </SectionTitle>
      <SuggestedPrompts
        endpoint="query/trending_prompts"
        handleQuerySubmit={handleQuerySubmit}
        setQuery={setQuery}
      />
      <SectionTitle>
        <SectionTitleIcon icon={faLightbulb} />
        Some Suggestions
      </SectionTitle>
      <SuggestedPrompts
        endpoint="query/suggested_prompts"
        handleQuerySubmit={handleQuerySubmit}
        setQuery={setQuery}
      />
      <QueryInput
        onChange={(event) =>
          setQuery({
            prompt: event.target.value,
            source: "original",
          })
        }
        onSubmit={handleQuerySubmit}
      />
    </>
  );
};

export default QueryPanelQuestion;
