import { useEffect, useState } from "react";
import theme from "../../themes.js";
import styled from "@emotion/styled";

import Box from "@mui/material/Box";
import QueryStyles from "./QueryStyles.js";
import QueryAnswer from "./QueryAnswer.js";
import StyledProgress from "../base/StyledProgress.js";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

import SubtleButton from "../button/SubtleButton.js";

const ExpectedTime = 10000;
const MaxTime = 20000; // ideally this gets passed straight into ApiService but not sure how

const PromptContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

const Prompt = styled("div")({
  ...QueryStyles,
  display: "inline-block",
  "&:hover": {},
});

const UserPrompt = ({ text, newPrompt }) => {
  return (
    <PromptContainer>
      <Prompt>
        <div style={{ padding: theme.bubblePadding.medium }}>{text}</div>
      </Prompt>
      <SubtleButton
        tooltipText="New prompt"
        icon={faPlusCircle}
        handleClick={newPrompt}
      />
    </PromptContainer>
  );
};

const StyledProgressContainer = styled("div")({
  position: "relative",
  width: "100%",
});

const ProgressMessage = styled("div")({
  color: theme.font.color.dim,
  textAlign: "center",
  margin: "20px 0px",
});

const AnswerContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "left",
});

function QueryPanelResult({
  prompt,
  result,
  queryError,
  setQueryError,
  newPrompt,
}) {
  const [showProgress, setShowProgress] = useState(true);
  const [showAnswer, setShowAnswer] = useState(false);
  const [possibleTimeout, setPossibleTimeout] = useState(true);

  const timeoutErrorMessage =
    "Sorry we are experiencing some delays. Please try again later.";

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (result === null && possibleTimeout) {
        setShowProgress(false);
        setShowAnswer(false);
        setQueryError(timeoutErrorMessage);
      }
    }, MaxTime);
    return () => clearTimeout(timeoutId);
  }, [result]);

  // handling updates
  useEffect(() => {
    if (result !== null && possibleTimeout) {
      setQueryError("");
      setShowAnswer(true);
      setShowProgress(false);
      setPossibleTimeout(false);
    }
  }, [result]);

  useEffect(() => {
    if (queryError) {
      setShowProgress(false);
      setShowAnswer(false);
      setPossibleTimeout(false);
    }
  }, [queryError]);

  return (
    <Box
      style={{
        alignContent: "left",
        width: "100%",
        maxWidth: "800px",
      }}
    >
      <AnswerContainer>
        <UserPrompt text={prompt} newPrompt={newPrompt} />
        {showProgress && (
          <StyledProgressContainer>
            <ProgressMessage>Searching our database...</ProgressMessage>
            <StyledProgress
              expectedTime={ExpectedTime}
              isComplete={result !== null}
            />
          </StyledProgressContainer>
        )}
        {showAnswer && <QueryAnswer result={result} />}
        {queryError && !showAnswer && (
          <ProgressMessage>{queryError}</ProgressMessage>
        )}
      </AnswerContainer>
    </Box>
  );
}

export default QueryPanelResult;
