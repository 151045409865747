import ApiService from "../services/ApiService";

async function SubmitRegisterVerify(completedForm, setErrorMessage) {
  try {
    const response = await ApiService("post", "user/verify", completedForm);
    if (response.status === 200) {
      return true;
    }
  } catch (error) {
    setErrorMessage(error.response.data.status_message);
    return false;
  }
}

export default SubmitRegisterVerify;
