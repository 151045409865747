import ApiService from "../services/ApiService";

async function SubmitPasswordResetRequest({
  email,
  setSuccessMessage,
  setErrorMessage,
}) {
  try {
    const response = await ApiService("post", "user/password_reset_request", {
      email,
    });
    if (response.status === 200) {
      setSuccessMessage(response.data.status_message);
    } else {
      setErrorMessage(response.data.status_message);
    }
  } catch (error) {
    setErrorMessage(error.response.data.status_message);
  }
}

export default SubmitPasswordResetRequest;
