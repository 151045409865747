import ApiService from "../services/ApiService";
import Cookies from "universal-cookie";

const cookies = new Cookies();

async function SubmitLogin({ email, password, setErrorMessage, navigate }) {
  try {
    const response = await ApiService("post", "user/login", {
      email,
      password,
    });
    const authToken = response.data?.auth_token;
    cookies.set("auth_token", authToken, {
      path: "/",
      secure: true,
    });
    const nextUrl = response.data?.next_url;
    navigate(nextUrl || "/ask");
  } catch (error) {
    setErrorMessage(error.response.data.status_message);
  }
}

export default SubmitLogin;
